import { Orders, Promotions } from 'cs-connect'
import React, { useEffect, useState } from 'react'
import { View, Text, Image } from 'react-native'
import TextField from './utils/TextField'
import Progress from './utils/Progress'
import Grid from '@mui/material/Grid';
import lodash from 'lodash'
import Button from './components/Button'
import CheckBox from './components/CheckBox'
import {
   useSelector,
   useDispatch
} from 'react-redux';
import {
   AppState
} from './store'
import { addPromotion, getPromotion, updatePromotion } from './store/promotions'
import { Alert } from './utils/Alert.web'
import { useHistory, Link, useParams, useLocation } from 'react-router-dom'
import moment from 'moment'
import {
  ChannelOptions,
   StatusOptions
} from 'cs-connect/lib/Promotions'
import {
   Actions
} from 'firestar'
import firebase from 'firebase/compat/app';
import { exportListToCSV } from 'cs-connect/lib/Utils'
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { SectionPanel } from './utils/SectionPanel'
import CheckPermission from './utils/CheckPermission'
// import FullCalendar from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid'

const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm'

function defaultPromotion(casinoId: string) {
   return {
      id: Actions.generateIDForPath(`casinos/${casinoId}/promotions`),
      status: "draft",
      startDate: new Date(),
      endDate: new Date(),
      name: ''
   } as Promotions.Promotion
}

export default function CreatePromotion() {

   const [showLoader, setShowLoader] = useState(false)
   const history = useHistory()
   const { promotionId } = useParams() as {
      promotionId?: string
   }

   const [clone] = useState(new URLSearchParams(window.location.search).get('clone'))

   const settings = useSelector((state: AppState) => state.settings)
   const promotions = useSelector((state: AppState) => state.promotions)

   const savedPromotion = promotions.find(p => p.id === promotionId)

   const dispatch = useDispatch()
   const casinoId = settings?.linkedCasinoId

   const [promotion, setPromotion] = useState<Promotions.Promotion>()

  useEffect(() => {

    if (promotionId != null) {
      if (savedPromotion) {
        setPromotion(savedPromotion)
      } else {
        fetchPromotion()
      }
    } else {
      if (casinoId) { setPromotion(defaultPromotion(casinoId)) }
    }
  }, [savedPromotion])

  useEffect(() => {

    if (clone != null && (clone ?? '').length > 0 && casinoId != null) {
      setShowLoader(true)
      Promotions.getPromotion(casinoId, clone).then((response) => {
        onChange({
          name: `Clone of ${response.name}`,
          acceptVouchers: response.acceptVouchers,
          validateLocation: response.validateLocation,
          allowTiers: response.allowTiers,
          enableMemberByDefault: response.enableMemberByDefault,
          disableClaim: response.disableClaim,
          channels: response.channels,
          limitClaims: response.limitClaims,
          allowMultipleClaims: response.allowMultipleClaims,
          allowShippingAddress: response.allowShippingAddress,
          thumbnail: response.thumbnail
        })
        setShowLoader(false)
      })
        .catch((error) => {
          setShowLoader(false)
          Alert.alert('Unable to clone', String(error))
        })
    }
  }, [clone])

   async function fetchPromotion() {

      console.log('promotionId', promotionId)
      if (promotionId == null) return
      setShowLoader(true)
      try {
         await dispatch(getPromotion(promotionId))
      } catch (error) {
         console.log('Error Getting Promotion : ', error)
      }
      setShowLoader(false)
   }

   if (promotion == null) {
      return <Progress />
   }

   return (
     <Grid xs={12} sm={8} md={8} lg={7} xl={6}>
       {showLoader == true && <Progress />}
       <View
         style={{
           padding: 15,
         }}>
         <View
           style={{
             flexDirection: "row",
             justifyContent: "space-between",
            //  marginVertical: 15,
             alignItems: 'center',
           }}>
           <Text
             style={{
               fontSize: 25,
             }}>
             {promotionId != null ? "Update Promotion" : "Create a Promotion"}
           </Text>
           <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: 'center',
            }}>
             {promotion.createdAt == null && (
               <Text
                 style={{
                   color: "gray",
                   fontStyle: "italic",
                   marginRight: 10,
                 }}>
                 Save this promotion in order to add gifts and members.
               </Text>
             )}
             <CheckPermission minimumRole="moderator">
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
                {promotion.status == 'published' && <Button
                  titleStyle={{
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                  type='outlined'
                  disabled={promotion.name.trim().length === 0}
                  style={{
                    margin: 5,
                    width: 180,
                  }}
                  onPress={onClickSwitchDraft}
                  title={"SWITCH TO DRAFT"}/>}
                <Button
                  titleStyle={{
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                  disabled={promotion.name.trim().length === 0}
                  containerStyle={{
                    margin: 5,
                    width: 150,
                  }}
                  onPress={onClickSave}
                  title={"SAVE"}/>
              </div>
             </CheckPermission>
             <CheckPermission minimumRole="admin">
              {promotion.createdAt != null && promotion.status !== "published" && (
                <Button
                  onPress={onPressPublish}
                  type="outlined"
                  style={{
                    width: 150,
                  }}
                  titleStyle={{
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                  title="PUBLISH"
                />
              )}
            </CheckPermission>
         </View>
         </View>
         <CheckPermission minimumRole="admin" type={'fade'}>
           <TextField
             onChangeText={(value) => onChangeValue("name", value)}
             value={promotion?.name}
             placeholder="Eg: Gift of the Week, Birthday Month"
             label="Promotion Name"
           />
         </CheckPermission>
         <View
           style={{
             flexDirection: "row",
             justifyContent: "space-between",
           }}
         >
           <View
             style={{
               marginTop: 25,
             }}
           >
             {promotionId != null && (
               <SectionPanel
                 title="Url"
                 render={() => {
                   return (
                     <View>
                       <a target={"_blank"} href={`https://app.infigifts.com/casinos/${casinoId}/promotions/${promotion.id}`}>
                         Open on Web
                       </a>
                     </View>
                   );
                 }}
               />
             )}
             <SectionPanel
               title="Status"
               render={() => {
                 return renderStatus(promotion);
               }}
             />
          <CheckPermission minimumRole="moderator" type='fade'>
            <SectionPanel
               title="Start Date"
               render={() => {
                 let startDate =
                   promotion.startDate as firebase.firestore.Timestamp;
                 let date = startDate?.toDate?.() ?? startDate ?? new Date();
                 const dateString = moment(date).format(DATE_TIME_FORMAT);

                 return (
                   <div>
                     <input
                       style={{
                         padding: 5,
                         width: 220,
                       }}
                       min={moment(new Date()).format(DATE_TIME_FORMAT)}
                       onChange={(event) => {
                         let date = moment(
                           event.target.value,
                           DATE_TIME_FORMAT
                         ).toDate();
                         onChangeValue("startDate", date);
                       }}
                       value={dateString}
                       type="datetime-local"
                     />
                     <span>&nbsp;&nbsp;{Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
                   </div>
                 );
               }}
             />
             <SectionPanel
               title="End Date"
               render={() => {
                 let endDate =
                   promotion.endDate as firebase.firestore.Timestamp;
                 let date = endDate?.toDate?.() ?? endDate ?? new Date();
                 const dateString = moment(date).format(DATE_TIME_FORMAT);
                 return (
                   <div>
                    <input
                     style={{
                       padding: 5,
                       width: 220,
                     }}
                     min={moment(new Date()).format(DATE_TIME_FORMAT)}
                     onChange={(event) => {
                       const date = moment(
                         event.target.value,
                         DATE_TIME_FORMAT
                       ).toDate();
                       onChangeValue("endDate", date);
                     }}
                     value={dateString}
                     type="datetime-local"
                   />
                    <span>&nbsp;&nbsp;{Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
                   </div>
                 );
               }}
             />
            </CheckPermission>
            <CheckPermission minimumRole="admin" type='fade'>
             <SectionPanel
               title="Options"
               render={() => {
                 return (
                   <div>
                     <CheckBox
                       containerStyle={{
                         marginLeft: 0,
                         backgroundColor: "white",
                       }}
                       onPress={() =>
                         onChangeValue(
                           "allowMultipleClaims",
                           !(promotion.allowMultipleClaims ?? false)
                         )
                       }
                       title="Allow Multiple Claims"
                       checked={promotion.allowMultipleClaims ?? false}/>
                      <CheckBox
                       containerStyle={{
                         marginLeft: 0,
                         backgroundColor: "white",
                       }}
                       onPress={() => onChangeValue("validateLocation",!(promotion.validateLocation ?? true))}
                       title="Location Check"
                       checked={promotion.validateLocation ?? true}/>
                     <CheckBox
                       containerStyle={{
                         marginLeft: 0,
                         backgroundColor: "white",
                       }}
                       onPress={() => {
                         const value = !(promotion.allowTiers ?? false);
                         onChangeValue("allowTiers", value);
                       }}
                       title="Allow Tiers"
                       checked={promotion.allowTiers ?? false}
                     />
                     <CheckBox
                       containerStyle={{
                         marginLeft: 0,
                         backgroundColor: "white",
                       }}
                       onPress={() => {
                         const value = !(
                           promotion.allowShippingAddress ?? false
                         );
                         onChangeValue("allowShippingAddress", value);
                       }}
                       title="Allow gifting a friend"
                       checked={promotion.allowShippingAddress ?? false}
                     />
                    <CheckBox
                         containerStyle={{
                           marginLeft: 0,
                           backgroundColor: "white",
                         }}
                       onPress={() => {
                         const value = !(
                           promotion.enableMemberByDefault ?? true
                         );
                         onChangeValue("enableMemberByDefault", value);
                       }}
                       title="Members Enabled by Default"
                       checked={promotion.enableMemberByDefault ?? true} />
                     <CheckBox
                       containerStyle={{
                         marginLeft: 0,
                         backgroundColor: "white",
                       }}
                       onPress={() => {
                         const value = !(
                           promotion.disableClaim ?? false
                         );
                         onChangeValue("disableClaim", value);
                       }}
                       title="Disable Claim"
                       checked={promotion.disableClaim ?? false} />
					<CheckBox
                       containerStyle={{
                         marginLeft: 0,
                         backgroundColor: "white",
                       }}
                       onPress={() => {
                         const value = !(promotion.redeemPoints ?? false);
                         onChangeValue("redeemPoints", value);
                       }}
                       title="Redeem Points"
                       checked={promotion.redeemPoints ?? false} />
                   </div>
                 );
               }}/>
              <SectionPanel
               title="Channels"
               render={() => {
                 return (
                    <div>
                      {Object.keys(ChannelOptions).map((key:any) => (
                        <div>
                          <CheckBox
                            containerStyle={{
                              marginLeft: 0,
                              backgroundColor: "white",
                            }}
                            onPress={() => {
                              const channels = promotion.channels ?? []
                              const index = channels.findIndex((c) => c === key)
                              if(index >= 0){
                                channels.splice(index, 1)
                              }else{
                                channels.push(key)
                              }
                              onChangeValue('channels', channels)
                            }}
                            title={ChannelOptions[key]}
                            checked={promotion.channels?.includes(key)}/>
                        </div>
                      ))}
                    </div>
                 )
               }}/>
             <SectionPanel
               title="Limit Settings"
               render={() => {
                 return (
                   <div>
                     <CheckBox
                       containerStyle={{
                         marginLeft: 0,
                         backgroundColor: "white",
                       }}
                       onPress={() => {
                         onChangeValue(
                           "limitClaims",
                           promotion.limitClaims == null ? 100 : null
                         );
                       }}
                       title="Limit Claims"
                       checked={promotion.limitClaims != null}
                     />
                     {promotion.limitClaims != null && (
                       <TextField
                         label="Number Of Claims"
                         value={promotion.limitClaims}
                         onChangeText={(value) => {
                           const number = Number(value.trim());
                           if (!isNaN(number)) {
                             onChangeValue("limitClaims", number);
                           }
                         }}
                       />
                     )}
                   </div>
                 );
               }}
             />
              {/* <FullCalendar
                // plugins={[dayGridPlugin]}
                initialView='dayGridMonth'
                weekends={false}
                events={[
                  { title: 'Meeting', start: new Date() }
                ]}
                eventContent={(eventInfo) => (
                  <>
                    <b>{eventInfo.timeText}</b>
                    <i>{eventInfo.event.title}</i>
                  </>
                )}
              /> */}
             <SectionPanel
               title="Vouchers Settings"
               render={() => {
                 return (
                   <View
                     style={{
                       paddingVertical: 2.5,
                     }}>
                     <CheckBox
                       containerStyle={{
                         marginLeft: 0,
                         backgroundColor: "white",
                       }}
                       onPress={() =>
                         onChangeValue(
                           "acceptVouchers",
                           !(promotion.acceptVouchers ?? false)
                         )
                       }
                       title="Accept Vouchers"
                       checked={promotion.acceptVouchers ?? false}/>
                     <Text
                       style={{
                         marginTop: 5,
                       }}>
                       {(promotion.acceptVouchers ?? false) == true && (
                         <Link
                           style={{
                             textDecoration: "none",
                           }}
                           to={`/promotions/${promotion.id}/vouchers`}>
                           Edit Vouchers →
                         </Link>
                       )}
                     </Text>
                   </View>
                 );
               }}
             />
             </CheckPermission>
           </View>
           <View style={{
              
           }}>
            <SectionPanel
              title="Thumbnail"
              render={() => {
                return (
                  <ImageUploading
                    value={[
                      {
                        dataURL: promotion.thumbnail ?? "",
                      },
                    ]}
                    onChange={onSelectCoverImage}>
                    {({ onImageUpload, onImageRemove }) => {
                      return (
                        <View
                          style={{
                            alignItems: "center",
                          }}>
                          <Image
                            style={{
                              width: 300,
                              height: 330,
                              borderWidth: 1,
                              borderColor: "#dddddd",
                              borderRadius: 2,
                            }}
                            resizeMode="contain"
                            source={{
                              uri: promotion.thumbnail ?? "",
                            }}
                          />
                          <CheckPermission minimumRole="admin" type='hide'>
                            <Button
                              type="text"
                              titleStyle={{
                                fontSize: 12,
                              }}
                              containerStyle={{
                                marginTop: 10,
                              }}
                              title={
                                promotion.thumbnail != null
                                  ? "Remove Image"
                                  : "Upload Image"
                              }
                              onPress={() => {
                                if (promotion.thumbnail) {
                                  onImageRemove(0);
                                } else {
                                  onImageUpload();
                                }
                              }}
                            />
                          </CheckPermission>
                        </View>
                      );
                    }}
                  </ImageUploading>
                );
              }}
            />
            {promotionId != null && (
                <SectionPanel
                  title="Export Data"
                  render={() => {
                    return (
                      <View
                        style={{
                          paddingVertical: 2.5,
                        }}
                      >
                        <Button
                          onPress={onPressExport}
                          type="outlined"
                          containerStyle={{
                            width: 120,
                          }}
                          titleStyle={{
                            textAlign: "left",
                            fontSize: 13,
                          }}
                          title="Export CSV"
                        />
                      </View>
                    );
                  }}
                />
              )}
              {promotion.createdAt != null && (
               <SectionPanel
                 title="Select Gifts and Members"
                 render={() => {
                   return (
                     <View
                       style={{
                         paddingVertical: 2.5,
                       }}>
                       <Text
                         style={{
                           marginVertical: 10,
                         }}>
                         <Link
                           style={{
                             textDecoration: "none",
                           }}
                           to={`/promotions/${promotionId}/members`}>
                           Members →
                         </Link>
                       </Text>
                       <Text
                         style={{
                           marginVertical: 10,
                         }}>
                         <Link style={{
                             textDecoration: "none",
                           }} to={`/promotions/${promotionId}/select-gifts`}>Select Gifts →</Link>                         
                       </Text>
                       <Text
                         style={{
                           marginVertical: 10,
                         }}>
                          <Link style={{
                                textDecoration: "none",
                              }} to={`/promotions/${promotionId}/bulk-order`}>Bulk Order →</Link>
                        </Text>
                     </View>
                   );
                 }}
               />
             )}
            </View>
         </View>
       </View>
     </Grid>
   );

   function onSelectCoverImage(value?: ImageListType) {
      if ((value ?? []).length > 0) {
         onChangeValue('thumbnail',value![0].dataURL ?? null)
      } else {
         onChangeValue('thumbnail',null)
      }
   }


  function onChange(changes: Partial<Promotions.Promotion>) {
    const __promotion = { ...promotion } as Promotions.Promotion
    setPromotion({
      ...__promotion,
      ...changes
    })
  }

   function onChangeValue(path: string, value: any) {
      const __promotion = { ...promotion } as Promotions.Promotion
      lodash.set(__promotion, path, value)
      setPromotion(__promotion)
   }

   async function onPressPublish() {
      if (casinoId == null || promotion == null) return
      setShowLoader(true)
      try {
         if (promotion.name.trim().length == 0) {
            throw 'Enter a name for the promotion'
         }
         await dispatch(updatePromotion(promotion.id, {
            ...promotion,
            status: 'published'
         }))
         history.push('/promotions')
      } catch (error) {
         Alert.alert('Error Creating Promotion', String(error))
      }
      setShowLoader(false)
   }

   async function onClickSwitchDraft() {

    setShowLoader(true)
    try {
      if(promotion?.id){
        await dispatch(updatePromotion(promotion.id, {
          ...promotion,
          status: 'draft'
        }))
      }
    } catch (error) {
      console.log('Error Switching Draft : ', error)
    }
    setShowLoader(false)
 }

   async function onClickSave() {

      if (casinoId == null || promotion == null) return
      setShowLoader(true)

      const __updatingPromotion = { 
         ...promotion,
         id: promotionId ?? Actions.generateIDForPath('promotions'),
      } as Promotions.Promotion

      try {

         if (promotion.thumbnail) {
            if (promotion.thumbnail?.startsWith('data')) {
               console.log('Uploading Thumbnail')
               const response = await Promotions.uploadThumbnail(
                  casinoId,
                  __updatingPromotion.id,
                  promotion.thumbnail
               )
               __updatingPromotion.thumbnail = response.downloadUrl
            }
         } else {
            __updatingPromotion.thumbnail = null
         }

      } catch (error) {
         console.log('Error Uploading Thumbnail : ', error)
      }

      try {
         if (__updatingPromotion.name.trim().length == 0) {
            throw new Error('Enter a name for the promotion')
         }
         await dispatch(addPromotion(__updatingPromotion))
         history.push('/promotions')
      } catch (error) {
         Alert.alert('Error Creating Promotion', String(error))
      }
      setShowLoader(false)
   }

   async function onPressExport() {

      if (promotionId == null) return
      setShowLoader(true)
      try {
         const response = await Orders.getOrdersForPromotion(promotionId, {
            limit: 10000
         })
         const orders = response.data.map((order) => {

            const phoneNumber = order.member.phoneNumber ?? ''
            return {
              ...order,
              trackingLink: order.overrideTrackingLink ?? order.trackingLink ?? '',
              member: {
                ...order.member,
                phoneNumber: phoneNumber.substring(phoneNumber.length - 10)
              }
            }
         })
         if (orders.length == 0) {
            Alert.alert('No Orders for this promotion')
         } else {
            const fileName = `${promotion?.name}_orders`
            exportListToCSV(orders, fileName,[
               'id',
               'metadata_name',
               'status',
               'userInfo_phoneNumber',
               'userInfo_uid',
               'member_name',
               'member_firstName',
               'member_lastName',
               'member_phoneNumber',
               'member_email',
               'member_cardNumber',
               'member_gender',
               'member_age',
               'member_address_street',
               'member_address_city',
               'member_address_state',
               'member_address_stateCode',
               'member_address_country',
               'member_address_zipCode',
               'casinoInfo_name',
               'product_title',
               'metadata_total_price',
               'metadata_created_at',
               'promotionInfo_name',
               'trackingLink',
			   'author_displayName',
			   'author_email'
            ])
         }
      } catch (error) {
         console.log('Error Exporting Orders', error)
         Alert.alert('Error Exporting Orders', String(error))
      }
      setShowLoader(false)
   }

}

export function renderStatus(promotion:Promotions.Promotion) {

   const endDate = promotion.endDate as firebase.firestore.Timestamp
   return (
      <View>
         <Text>{endDate?.toDate ? (endDate.toDate().getTime() < (new Date()).getTime() ? (
            <Text style={{
               flex: 1,
               backgroundColor: '#dddddd',
               color: 'black',
               paddingHorizontal: 15,
               paddingVertical: 2,
               borderRadius: 5
            }}>Expired</Text>
         ) : (
            <Text style={{
               flex: 1,
               backgroundColor: 'green',
               color: 'white',
               paddingHorizontal: 15,
               paddingVertical: 2,
               borderRadius: 5
            }}>{StatusOptions[promotion.status]}</Text>
         )) : '--'}</Text>
      </View>
   )
}

